import styled, { css } from 'styled-components';
import { SingleLineCell } from '../../atoms/table/Table.style';
import { color } from '../../config';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.2rem;
`;

export const TitleWithTag = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 2rem;
  }
`;

export const Timestamps = styled.div`
  padding-right: 0.6rem;
  & > span {
    display: block;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
`;

export const Tag = styled.div<{ isCompleted: boolean }>`
  display: inline-block;
  border: 1px solid
    ${(props) =>
      props.isCompleted ? color.primary.base : color.error.foreground};
  color: ${(props) =>
    props.isCompleted ? color.primary.base : color.error.foreground};
  background-color: ${(props) =>
    props.isCompleted ? color.primary.lightest : color.error.background};
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  border-radius: 0.8rem;
`;

export const ScreenActions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;

  button {
    margin-left: 1rem;
  }
`;

export const RightContent = styled.div<{ pushDown: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding-top: ${(props) => (props.pushDown ? '3.4rem' : '0')};
`;

export const Container = styled.div`
  margin: 3.8rem;
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(44, 51, 63, 0.08);
`;

export const Divider = styled.div`
  border-left: 1px solid ${color.grey.light};
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 0;
`;

export const LearnerEmployer = styled.div`
  position: relative;
  overflow: hidden;
`;

const EmployerDataCardDefault = css`
  margin-top: 34px;
  &:before {
    height: 2.4rem;
    width: 2px;
    background-color: ${color.grey.light};
    top: -30px;
    left: 50%;
  }
`;

const EmployerDataCardFirst = css`
  &:before {
    height: 2px;
    width: 2.4rem;
    background-color: ${color.primary.base};
    top: 50%;
    left: -30px;
  }
`;

export const EmployerDataCard = styled.div<{ isFirst?: boolean }>`
  border-radius: 0.8rem;
  border: 1px solid ${color.grey.lightest};
  min-height: 15.6rem;
  position: relative;
  display: block;
  padding: 2rem;
  margin-left: 8px;

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  p:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &:before {
    content: '';
    position: absolute;
  }

  ${(props) => {
    if (!props.isFirst) {
      return EmployerDataCardDefault;
    }
    return EmployerDataCardFirst;
  }}
`;

export const LearnerDetailsContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;

  @media only screen and (min-width: 50rem) {
    flex-direction: row;
  }
`;
export const LearnerDetailsColumns = styled.div`
  width: 100%;
  height: 9rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (min-width: 50rem) {
    width: 50%;
  }
`;

export const AdminDataCard = styled.div<{ isFirst?: boolean }>`
  border-radius: 0.8rem;
  border: 1px solid ${color.grey.lightest};
  min-height: 3.6rem;
  position: relative;
  display: block;
  padding: 2rem;
  margin-left: 8px;
  margin-top: 2rem;

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  p:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &:before {
    content: '';
    position: absolute;
  }

  ${(props) => {
    if (!props.isFirst) {
      return EmployerDataCardDefault;
    }
    return EmployerDataCardFirst;
  }}
`;

export const LearnerDataCardItem = styled.div`
  position: relative;
  display: inline-block;
  padding-bottom: 0.85rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (min-width: 50rem) {
    padding-right: 1.25rem;
  }
`;

export const TutorDataCardItem = styled.div`
  padding-bottom: 0.85rem;
  text-align: left;
  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      span {
        color: blue;
      }
    }
  }
`;

export const AdminDataCardItem = styled.div`
  margin-top: 1rem;
  padding-bottom: 0.85rem;
  text-align: left;
  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      span {
        color: blue;
      }
    }
  }
`;
