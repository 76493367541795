import React, { SyntheticEvent, useEffect, useState } from 'react';
import { IEmployerInList } from '../../../context-providers/employer/employer-reducer';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { usePagedRequest } from '../../../utils/paged-request';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';
import { FilterItem } from '../../atoms/filter/models/filter-item';
import Select, { IOption } from '../../atoms/form-fields/select/Select';
import Stack from '../../atoms/stack/Stack';
import { IconArrowRight } from '../../icons';
import { useEditLearner } from './useEditLearner';
import FilterService from '../../atoms/filter/service/filter-service';
import { FilterEntityResponse } from '../../atoms/filter/models/filter-response';

export interface IAssignEmployerToLearner {
  loadLearner?: () => void;
}

const AssignEmployerToLearner = ({ loadLearner }: IAssignEmployerToLearner) => {
  const closeModal = useCloseModal();
  const { learner, update, save } = useEditLearner();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [employers, setEmployers] = useState<FilterEntityResponse[]>([]);
  const [sectors, setSectors] = useState<FilterEntityResponse[]>([]);

  const load = async () => {
    let response = await FilterService.loadEmployers();

    if (response.success) {
      setEmployers(response.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const employer = employers.find((d) => d.id === learner?.employerId);

  const employerOptions: FilterItem[] = employers.map((i) => ({
    id: i.id,
    name: i.name,
  }));

  const onEmployerChanged = (selectedItem: FilterItem): void => {
    const employer = employers.find((l) => l.id === selectedItem.id) || null;
    update(String(employer?.id), 'employerId');
    if (employer) {
      loadSectors(employer.id);
    }
  };

  const loadSectors = async (employerId: string) => {
    let response = await FilterService.loadSectors(employerId);

    if (response.success) {
      setSectors(response.body);
    }
  };

  const sectorOptions =
    sectors.map(
      (d): IOption => ({
        label: d.name,
        value: d.id,
      }),
    ) || [];

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (step === 0 && employer) {
      setStep(1);
    } else if (step === 1 && learner?.sectorId) {
      const res = await save();
      if (res.success) {
        loadLearner && loadLearner();
        closeModal();
      }
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (isMenuOpen: boolean): void => {
    setIsMenuOpen(isMenuOpen);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={isMenuOpen ? { maxWidth: 412, height: 550 } : { maxWidth: 412 }}
    >
      <Stack stackMultiplier={2}>
        <h2>Add employer details</h2>
        {step === 0 && (
          <>
            <Stack>
              <h3>Assign employer</h3>
              <p>Which employer is the learner enroling with?</p>
              <FilterSingleItem
                filterName="employer-filter"
                items={employerOptions}
                title="Filter by employer"
                searchTitle="Search employer"
                onItemChanged={onEmployerChanged}
                toggleMenu={toggleMenu}
              />
            </Stack>
            <ButtonRow>
              <Button
                onClick={() => closeModal()}
                variant="subtle"
                dataQa="editEmployer-btn-cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={() => setStep(1)}
                type="submit"
                disabled={!employer}
                iconRight={<IconArrowRight responsive />}
                dataQa="editEmployer-btn-add"
              >
                Add sector
              </Button>
            </ButtonRow>
          </>
        )}
        {step === 1 && (
          <>
            <Select
              placeholder="Select sector..."
              options={sectorOptions}
              id="select-sector"
              label="Assign sector"
              description={`Which sector as ${
                employer?.name || ''
              } are they enroling?`}
              onOptionChanged={(option) => update(option.value, 'sectorId')}
              dataQa="editEmployer-dropdown-sector"
              attachOutsideOfModal
            />
            <ButtonRow>
              <Button
                onClick={() => setStep(0)}
                type="button"
                variant="subtle"
                dataQa="editEmployer-btn-back"
              >
                Back
              </Button>
              <Button
                type="submit"
                disabled={!learner?.sectorId}
                dataQa="editEmployer-btn-save"
              >
                Save details
              </Button>
            </ButtonRow>
          </>
        )}
      </Stack>
    </form>
  );
};

export default AssignEmployerToLearner;
