import styled from 'styled-components';
import { color } from '../config';

export const StaticToolTipWrapper = styled.div`
  position: relative;
  overflow: visible;
  align-self: center;

  &:hover {
    & .StaticToolTip {
      display: block;
      /* position: relative; */
    }
  }
`;
export const StaticToolTipElement = styled.div`
  position: absolute;
  display: none;
  top: -55px;
  background: ${color.grey.base};
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
  color: ${color.light};
  width: max-content;
  left: calc(-50% - 14px);
  border: solid ${color.grey.base} 1px;
  max-width: 400px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: ${color.grey.base} transparent transparent transparent;
  }
`;

export const StaticToolTipPrePopulatedElement = styled(StaticToolTipElement)`
  top: -7.5rem;
  left: calc(-26% - 0.79rem);
`;

export const StaticToolTipLearnerDetails = styled(StaticToolTipElement)`
  top: -6.75rem;
  left: calc(-4% - -0.21rem);
  max-width: 400px;
  white-space: normal;
  word-break: break-word;

  @media only screen and (min-width: 50rem) {
    left: calc(-2% - 2.79rem);
  }
`;

export const StaticToolTipLearnerLLDDDetails = styled(
  StaticToolTipLearnerDetails,
)`
  top: -10.75rem;
`;
