import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import DataCard, { DataCardItem } from '../../atoms/data-card/DataCard';
import Stack from '../../atoms/stack/Stack';
import IconButton from '../../atoms/icon-button/IconButton';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { IconDownload, IconPlusCircle, IconEdit } from '../../icons';
import {
  LearnerEmployer,
  EmployerDataCard,
  ScreenActions,
  TutorDataCardItem,
  AdminDataCardItem,
  AdminDataCard,
} from './Learner.style';
import { combineAddress } from '../../../utils/address';
import { useLearner } from '../../../context-providers/learner/learner-hooks';
import { color } from '../../config';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { Permission } from '../../../context-providers/enums/global-enums';
import { getUserClaims } from '../../../utils/jwt-decode';
import { deleteQueryParams } from '../../../utils/query-vars';
import Button from '../../atoms/button/Button';
import { DataCardIcon } from '../../atoms/data-card/DataCard.style';
import ExportLearnerHelper, {
  ILearnerCentre,
} from '../../../utils/exportLearnerHelper';
import AssignCentreToLearner from '../../modals/learner/AssignCenterToLearner';
import AssignEmployerToLearner from '../../modals/learner/AssignEmployerToLearner';
import AssignLearnerAdmins from '../../modals/learner/AssignLearnerAdmins';
import EditLearnerAdmins from '../../modals/learner/EditLearnerAdmins';
import ExportLearnerDetails from '../../modals/learner/ExportLearnerDetails';
import LearnerAddTutor from '../../modals/learner/LearnerAddTutor';

const EditLearnerEmployerDetails = () => {
  const user = getUserClaims();
  let canAssignAdmins: boolean = false;

  const showModal = useShowModal();

  if (user) {
    canAssignAdmins = userHasPermission(
      user.permissions,
      Permission.AssignAdmin,
    );
  }

  const { learner, loadLearner } = useLearner();
  if (!learner) {
    return null;
  }

  const assignedAdmins: string[] = learner.admins
    ? learner.admins.split(',')
    : [];

  const [centres, setCentres] = useState<ILearnerCentre[]>([]);
  const [centresLoading, setCentresLoading] = useState<boolean>(false);
  const [centresLoaded, setCentresLoaded] = useState<boolean>(false);
  const getCentres = (): void => {
    if (!centresLoaded && !centresLoading) {
      setCentresLoading(true);
      ExportLearnerHelper.getCentres().then((response) => {
        setCentres(response);
        setCentresLoaded(true);
      });
    }
  };
  useEffect(() => {
    getCentres();
  }, [centresLoaded]);

  const employerAddress = combineAddress(learner?.employer?.address);

  const exportError = !learner.isCompleted
    ? 'Learner details must be completed before exporting'
    : '';

  const clearUrlParams = () => {
    deleteQueryParams('admins');
  };

  const showCentreEdit = learner?.enrolmentStatus === 1 && centres.length > 1;
  const hasCentres = centres.length > 0;

  return (
    <>
      <ReactTooltip effect="solid" backgroundColor={color.grey.base} />
      <ScreenActions>
        <span data-tip={exportError} data-tip-disable={!exportError}>
          <IconButton
            disabled={!!exportError}
            icon={<IconDownload responsive />}
            onClick={() => showModal(ExportLearnerDetails)}
            dataQa="learner-btn-export"
          >
            Export (CSV, XML)
          </IconButton>
        </span>
      </ScreenActions>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Employer and course details</h2>
          <p>Assign employer and course to the learner.</p>
        </Stack>
        <LearnerEmployer>
          <GridFlex>
            <GridFlexItem small={5}>
              <DataCard
                title="Employer details"
                onEdit={() => {
                  showModal(AssignEmployerToLearner, { loadLearner });
                }}
                isEmpty={!learner.employer?.name && !learner.employer?.sector}
                dataQa="learner-btn-edit-employer"
              >
                <DataCardItem
                  value={learner.employer?.name || ''}
                  label="Name"
                  dataQa="learner-lbl-employer"
                />
                <DataCardItem
                  value={learner.employer?.sector || ''}
                  label="Sector"
                  dataQa="learner-lbl-sector"
                />
              </DataCard>
              <AdminDataCard isFirst>
                {learner && (
                  <>
                    <h3>Apprentice Admin(s)</h3>
                    {learner.adminIds && (
                      <AdminDataCardItem>
                        {' '}
                        <p>
                          Apprentice Admin(s)
                          <IconButton
                            data-qa="learnermodal-editAdmin-btn"
                            icon={<IconEdit responsive />}
                            onClick={() => {
                              showModal(EditLearnerAdmins, {
                                learner,
                                onComplete: loadLearner,
                              });
                            }}
                            disabled={!canAssignAdmins}
                          >
                            <span className="sr-only">Edit admins</span>
                          </IconButton>
                        </p>
                        <ul>
                          {assignedAdmins.map((s) => (
                            <li key={s}> {s}</li>
                          ))}
                        </ul>
                      </AdminDataCardItem>
                    )}
                    {!learner.adminIds && (
                      <AdminDataCardItem>
                        {' '}
                        <p>
                          <IconButton
                            data-qa="learnermodal-addAdmin-btn"
                            icon={<IconPlusCircle responsive />}
                            onClick={() => {
                              clearUrlParams();
                              showModal(AssignLearnerAdmins, {
                                learner,
                                onComplete: loadLearner,
                              });
                            }}
                            disabled={!canAssignAdmins}
                          >
                            Assign Admins
                            <span className="sr-only">Assign Admins</span>
                          </IconButton>
                        </p>
                      </AdminDataCardItem>
                    )}
                  </>
                )}
              </AdminDataCard>
            </GridFlexItem>
            <GridFlexItem small={5}>
              <EmployerDataCard isFirst>
                {learner.employer?.address && (
                  <>
                    <h3>Employer address</h3>
                    <p data-qa="learner-lbl-empAddress">
                      {employerAddress || '-'}
                    </p>
                  </>
                )}
              </EmployerDataCard>
              <EmployerDataCard>
                {learner.employer?.contact?.email && (
                  <>
                    <h3>Employer contact</h3>
                    <p data-qa="learner-lbl-empJob">
                      {learner.employer?.contact?.jobTitle || '-'}
                    </p>
                    <p data-qa="learner-lbl-empContact">
                      {learner.employer?.contact?.name || '-'}
                    </p>
                    <p data-qa="learner-lbl-empEmail">
                      {learner.employer?.contact?.email || '-'}
                    </p>
                  </>
                )}
              </EmployerDataCard>

              <EmployerDataCard>
                {learner.employer?.course && (
                  <>
                    <h3>Course details</h3>
                    <p data-qa="learner-lbl-course">
                      {learner.employer?.course?.name || '-'}
                    </p>
                    <p data-qa="learner-lbl-learningaim">
                      {learner.employer?.course?.learningAimTitle || '-'}
                    </p>
                    {learner.tutor && (
                      <TutorDataCardItem>
                        {' '}
                        <p>
                          Tutor: {learner.tutor}
                          <IconButton
                            data-qa="learnermodal-addTutor-btn"
                            icon={<IconEdit responsive />}
                            onClick={() =>
                              showModal(LearnerAddTutor, { loadLearner })
                            }
                          >
                            <span className="sr-only">Edit Tutor</span>
                          </IconButton>
                        </p>
                      </TutorDataCardItem>
                    )}
                    {!learner.tutor && (
                      <TutorDataCardItem>
                        {' '}
                        <p>
                          Tutor:
                          <IconButton
                            data-qa="learnermodal-addTutor-btn"
                            icon={<IconPlusCircle responsive />}
                            onClick={() =>
                              showModal(LearnerAddTutor, { loadLearner })
                            }
                          >
                            Assign Tutor
                            <span className="sr-only">Assign Tutor</span>
                          </IconButton>
                        </p>
                      </TutorDataCardItem>
                    )}
                    {hasCentres && (
                      <>
                        {' '}
                        <strong>Centre</strong>:&nbsp;
                      </>
                    )}
                    {(learner?.centre?.name &&
                      ((showCentreEdit && (
                        <>
                          {learner.centre.name}
                          <DataCardIcon
                            onClick={() =>
                              showModal(AssignCentreToLearner, {
                                loadLearner,
                                centres,
                              })
                            }
                            data-qa="edit-centre-btn"
                          >
                            <IconEdit />
                            <span className="sr-only">Edit centre</span>
                          </DataCardIcon>
                        </>
                      )) || <>{learner.centre.name}</>)) ||
                      (hasCentres && (
                        <Button
                          iconLeft={<IconPlusCircle />}
                          type="button"
                          dataQa="btn-assign-centre"
                          onClick={() =>
                            showModal(AssignCentreToLearner, {
                              loadLearner,
                              centres,
                            })
                          }
                        >
                          Assign Centre
                        </Button>
                      ))}
                  </>
                )}
              </EmployerDataCard>
            </GridFlexItem>
          </GridFlex>
        </LearnerEmployer>
      </Stack>
    </>
  );
};

export default EditLearnerEmployerDetails;
