import { Link } from 'gatsby';
import React from 'react';
import ReactGA from '../../../utils/google-analytics';
import { useCurrentLearner } from '../../../context-providers/learner/learner-context';
import { formatDate } from '../../../utils/dates';
import { usePagedRequest } from '../../../utils/paged-request';
import { LinkButton } from '../../atoms/button/Button';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import IconButton from '../../atoms/icon-button/IconButton';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import AssignmentStatusBadge from '../../atoms/status-badge/AssignmentStatusBadge';
import Table from '../../atoms/table/Table';
import { TableActions } from '../../atoms/table/Table.style';
import {
  IconDelete,
  IconDownload,
  IconArrowCircle,
  IconPortfolio,
  IconShow,
} from '../../icons';
import { RightContent, ScreenActions } from './Learner.style';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import configLoader from '../configuration/configuration-loader';
import { fetchAPI } from '../../../utils/fetch-api';
import { PagedFormAssignment } from '../../../context-providers/forms/paged-form-assignment';
import { FormAssignmentStatus } from '../../../context-providers/forms/form-assignment-status';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { Permission } from '../../../context-providers/enums/global-enums';
import { getUserClaims } from '../../../utils/jwt-decode';
import DownloadForm from '../../modals/forms/DownloadForm';
import DeleteData from '../../modals/forms/DeleteData';

const LearnerTaks = () => {
  const user = getUserClaims();
  let canDeleteReviewForm: boolean = false;

  if (user) {
    canDeleteReviewForm = userHasPermission(
      user.permissions,
      Permission.DeleteReviewForm,
    );
  }

  const learner = useCurrentLearner();
  const showModal = useShowModal();
  const config = configLoader();

  const reviewForms = usePagedRequest<PagedFormAssignment>(
    'v2/forms/search/formassignments',
    {
      sortColumnName: 'lastModified',
      learnerId: learner?.id || '',
    },
  );

  const onConfirmDelete = async (formAssignment: string | null) => {
    const res = await fetchAPI<string>({
      path: `v2/forms/delete/${formAssignment}`,
      method: 'DELETE',
      responseType: 'text',
    });
    if (res.success) {
      window.location.reload();
    }
  };

  const renderRow = (data: PagedFormAssignment) => [
    data.formName || '-',
    formatDate(data.learnerDateSent) || '-',
    <AssignmentStatusBadge
      status={
        data.learnerFormAssignmentStatus === FormAssignmentStatus.NULL
          ? FormAssignmentStatus.NotStarted
          : data.learnerFormAssignmentStatus
      }
    />,

    <TableActions key="3">
      {data.overallAssignmentStatus === FormAssignmentStatus.Completed ? (
        <Link
          to={`/learner/review-completed-form/?fid=${data.id}&lid=${learner?.id}&lln=${learner?.surname}&lfn=${learner?.firstName}`}
        >
          <IconShow responsive />
          <span className="sr-only">View completed form</span>
        </Link>
      ) : (
        ' '
      )}

      {data.overallAssignmentStatus === FormAssignmentStatus.Completed &&
      config.EnablePdfExport ? (
        <button
          type="button"
          data-qa={`forms-btn-download-${
            data?.formName?.replace(/\s/g, '-') ?? 'empty'
          }`}
          onClick={() =>
            showModal(DownloadForm, {
              formId: data.id,
            })
          }
        >
          <IconDownload responsive />
          <span className="sr-only">Download {data.id} form</span>
        </button>
      ) : (
        ' '
      )}

      {data.overallAssignmentStatus === FormAssignmentStatus.NotStarted &&
        (data.learnerFormAssignmentStatus === FormAssignmentStatus.NULL ||
          data.learnerFormAssignmentStatus ===
            FormAssignmentStatus.NotStarted) &&
        (data.trainingProviderFormAssignmentStatus ===
          FormAssignmentStatus.NULL ||
          data.trainingProviderFormAssignmentStatus ===
            FormAssignmentStatus.NotStarted) &&
        (data.employerFormAssignmentStatus === FormAssignmentStatus.NULL ||
          data.employerFormAssignmentStatus ===
            FormAssignmentStatus.NotStarted) && (
          <button
            type="button"
            data-qa={`forms-btn-delete-${
              data?.formName?.replace(/\s/g, '-') ?? 'empty'
            }`}
            onClick={() =>
              showModal(DeleteData, {
                onConfirmDelete: () => {
                  onConfirmDelete(data.id);
                },
              })
            }
            disabled={!canDeleteReviewForm}
          >
            <IconDelete fill={!canDeleteReviewForm ? '#ccc' : ''} responsive />
            <span className="sr-only">Delete {data.formName} form</span>
          </button>
        )}
    </TableActions>,
    data.learnerFormAssignmentStatus === FormAssignmentStatus.NULL ||
    data.learnerFormAssignmentStatus === FormAssignmentStatus.NotStarted ||
    data.learnerFormAssignmentStatus === FormAssignmentStatus.Started ? (
      <LinkButton
        key="3"
        to={`/send-learner-form/?fid=${data.id}`}
        dataQa={`send-learner-form-${data.formName}`}
        id={data.id}
      >
        Resend Mail
      </LinkButton>
    ) : data.learnerFormAssignmentStatus === FormAssignmentStatus.Rejected ? (
      <LinkButton
        key="3"
        to={`/send-learner-reject-form/?fid=${data.id}`}
        dataQa={`send-learner-reject-form-${data.formName}`}
        id={data.id}
      >
        Resend Mail
      </LinkButton>
    ) : data.learnerFormAssignmentStatus ===
        FormAssignmentStatus.ReadyForReview ||
      data.employerFormAssignmentStatus ===
        FormAssignmentStatus.ReadyForReview ? (
      <LinkButton
        id={data.id}
        to={`/review-learner-form/?fid=${data.id}`}
        dataQa={`review-form-${data.formName}`}
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Review forms - review and approve',
          });
        }}
      >
        Review
      </LinkButton>
    ) : (
      ' '
    ),
  ];

  return (
    <>
      <Stack stackMultiplier={2}>
        <GridFlex>
          <GridFlexItem small={6}>
            <Stack stackMultiplier={0.5}>
              <h2>Tasks</h2>
              <p>
                You can view any outstanding forms which need to be completed by
                the learner.
              </p>
            </Stack>
          </GridFlexItem>
          <GridFlexItem small={6}>
            <ScreenActions>
              <IconButton
                variant="white"
                disabled
                icon={<IconPortfolio responsive />}
              >
                Confirm all tasks complete
              </IconButton>
            </ScreenActions>
            <RightContent pushDown>
              <LinkButton
                to="/list-forms/"
                iconRight={<IconArrowCircle responsive />}
                dataQa="learner-btn-send"
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Learner tasks - send new form',
                  });
                }}
              >
                Send new form
              </LinkButton>
            </RightContent>
          </GridFlexItem>
        </GridFlex>
        <Table
          caption="List of learner tasks"
          data={reviewForms.data}
          headerItems={[
            { sortColumnName: 'formName', label: 'Form name' },
            { sortColumnName: 'lastModified', label: 'Date added' },
            { sortColumnName: 'status', label: 'Status' },
            { label: 'Actions' },
            { label: '' },
          ]}
          renderRow={renderRow}
          paged={reviewForms.pagedParams}
        />
        <Paging pageCount={reviewForms.pageCount} />
      </Stack>
    </>
  );
};

export default LearnerTaks;
