import React from 'react';
import ReactGA from '../../../utils/google-analytics';
import { useLearner } from '../../../context-providers/learner/learner-hooks';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { combineAddress } from '../../../utils/address';
import { formatDate, formatDateTime } from '../../../utils/dates';
import DataCard, { DataCardItem } from '../../atoms/data-card/DataCard';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import SideTabs from '../../atoms/tabbed/SideTabs';
import EditableTitle from '../../atoms/title/EditableTitle';
import { FullWidthContainer } from '../../layout/Layout.style';
import EditLearnerEmployerDetails from './EditLearnerEmployerDetails';
import {
  Container,
  Header,
  Tag,
  Timestamps,
  TitleWithTag,
  LearnerDetailsContainer,
  LearnerDetailsColumns,
  LearnerDataCardItem,
} from './Learner.style';
import LearnerTasks from './LearnerTasks';
import TooltipLearnerDetails from './../static-tooltip-learner-details';
import TooltipLearnerLLDDDetails from './../static-tooltip-learner-lldd-details';
import EditLearnerAddress from '../../modals/learner/EditLearnerAddress';
import EditLearnerContact from '../../modals/learner/EditLearnerContact';
import EditLearnerDetails from '../../modals/learner/EditLearnerDetails';
import EditLearnerName from '../../modals/learner/EditLearnerName';

const isBrowser = typeof window !== 'undefined';

const LearnerDetails = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const showModal = useShowModal();
  const { learner, loadLearner } = useLearner(true);
  if (!learner) {
    return null;
  }

  const { dateOfBirth, uln, nationalInsuranceNumber, contact, address } =
    learner;
  const { homePhoneNumber, mobilePhoneNumber, emailAddress } = contact || {};
  const joinedAddress = combineAddress(address);
  const ethnicityValue = learner.ethnicity ? learner.ethnicity : '';
  const llddValue = learner.lldd ? learner.lldd : '';
  const sexValue = learner.sex ? learner.sex : '';

  return (
    <>
      <FullWidthContainer>
        <Header>
          <TitleWithTag>
            <EditableTitle
              title={`${learner.firstName} ${learner.surname}`}
              onEdit={() => {
                showModal(EditLearnerName, { loadLearner });
              }}
              isEmpty={!learner.firstName}
            />
            <Tag isCompleted={!!learner.completedStatus}>
              {learner.completedStatus ? 'Completed' : 'Incomplete'}
            </Tag>
          </TitleWithTag>
          <Timestamps>
            <span>Created on {formatDateTime(learner.createdOn)}</span>
            <span>
              <strong>
                Last modified {formatDateTime(learner.lastModified)}
              </strong>
            </span>
          </Timestamps>
        </Header>
        <GridFlex align="flex-start">
          <GridFlexItem small={4}>
            <DataCard
              hasBackground
              title="Learner details"
              onEdit={() => {
                showModal(EditLearnerDetails, { loadLearner });
              }}
              isEmpty={
                !dateOfBirth &&
                !uln &&
                !nationalInsuranceNumber &&
                !ethnicityValue &&
                !sexValue &&
                !llddValue
              }
              dataQa="learner-btn-edit-details"
            >
              <LearnerDetailsContainer>
                <LearnerDetailsColumns>
                  <LearnerDataCardItem>
                    <DataCardItem
                      required
                      value={formatDate(dateOfBirth)}
                      label="DOB"
                      dataQa="learner-lbl-dob"
                    />
                  </LearnerDataCardItem>
                  <LearnerDataCardItem>
                    <DataCardItem
                      required
                      value={uln}
                      label="ULN"
                      dataQa="learner-lbl-uln"
                    />
                  </LearnerDataCardItem>
                  <LearnerDataCardItem>
                    <DataCardItem
                      required
                      value={nationalInsuranceNumber}
                      label="NI"
                      dataQa="learner-lbl-ni"
                    />
                  </LearnerDataCardItem>
                </LearnerDetailsColumns>

                <LearnerDetailsColumns>
                  <LearnerDataCardItem>
                    <TooltipLearnerDetails text={ethnicityValue}>
                      <DataCardItem
                        value={ethnicityValue}
                        label="Ethnicity"
                        dataQa="learner-lbl-ethnicity"
                      />
                    </TooltipLearnerDetails>
                  </LearnerDataCardItem>
                  <LearnerDataCardItem>
                    <DataCardItem
                      value={sexValue}
                      label="Sex"
                      dataQa="sex-lbl-sex"
                    />
                  </LearnerDataCardItem>
                  <LearnerDataCardItem>
                    <TooltipLearnerLLDDDetails text={llddValue}>
                      <DataCardItem
                        value={llddValue}
                        label="LLDD"
                        dataQa="learner-lbl-LLDD"
                      />
                    </TooltipLearnerLLDDDetails>
                  </LearnerDataCardItem>
                </LearnerDetailsColumns>
              </LearnerDetailsContainer>
            </DataCard>
          </GridFlexItem>

          <GridFlexItem small={4}>
            <DataCard
              hasBackground
              title="Contact"
              onEdit={() => {
                showModal(EditLearnerContact, { loadLearner });
              }}
              isEmpty={!homePhoneNumber && !mobilePhoneNumber && !emailAddress}
              dataQa="learner-btn-edit-contact"
            >
              <DataCardItem
                required
                value={homePhoneNumber}
                label="Home"
                dataQa="learner-lbl-home"
              />
              <DataCardItem
                required
                value={mobilePhoneNumber}
                label="Mobile"
                dataQa="learner-lbl-phone"
              />
              <DataCardItem
                required
                value={emailAddress}
                label="Email"
                dataQa="learner-lbl-email"
              />
            </DataCard>
          </GridFlexItem>
          <GridFlexItem small={4}>
            <DataCard
              hasBackground
              title="Home address"
              onEdit={() => {
                showModal(EditLearnerAddress, { loadLearner });
              }}
              isEmpty={
                !learner.address?.building &&
                !learner.address?.street &&
                !learner.address?.city &&
                !learner.address?.county &&
                !learner.address?.postcode
              }
              dataQa="learner-btn-edit-address"
            >
              <DataCardItem
                required
                hideLabel
                value={joinedAddress}
                label="Address"
                dataQa="learner-lbl-address"
              />
            </DataCard>
          </GridFlexItem>
        </GridFlex>
      </FullWidthContainer>
      <Container>
        <SideTabs
          tabs={[
            {
              key: 'details',
              icon: 'IconPortfolio',
              title: 'Details',
              component: EditLearnerEmployerDetails,
            },
            {
              key: 'tasks',
              icon: 'IconTimesheet',
              title: 'Tasks',
              component: LearnerTasks,
              count: 0,
            },
          ]}
        />
      </Container>
    </>
  );
};

export default LearnerDetails;
