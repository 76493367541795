import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';

import Stack from '../../atoms/stack/Stack';
import { CreateCourseHeader } from '../ModalContent.style';
import { IconError, IconPlus } from '../../icons';

import Select, { IOption } from '../../atoms/form-fields/select/Select';
import { ILearnerTutor } from '../../../context-providers/learner/learner-reducer';
import { useEditLearner } from './useEditLearner';
import { ErrorMessage } from '../data-tables/data-tables.style';

interface ILearnerAddTutor {
  loadLearner: () => void;
}

const LearnerAddTutor = ({ loadLearner }: ILearnerAddTutor) => {
  const closeModal = useCloseModal();
  const initialTutor: ILearnerTutor = { id: 0, definition: 'None selected' };
  const { learner, update, save } = useEditLearner();
  const [selectedTutor, setSelectedTutor] = useState<string | undefined>();
  const courseId = learner?.employer?.course?.id;
  const [tutors, setTutors] = useState<ILearnerTutor[]>([initialTutor]);
  const [initialOption, setInitialOption] = useState<IOption | undefined>();

  const loadTutors = useCallback(async () => {
    const res = await fetchAPI<ILearnerTutor[]>({
      path: `courses/tutors/${courseId}`,
      method: 'GET',
    });

    if (res.success) {
      const currentTutor = res.body.find((t) => t.id === learner?.tutorId);
      if (currentTutor) {
        setSelectedTutor(currentTutor.id.toString());
        setInitialOption({
          value: currentTutor.id.toString(),
          label: currentTutor.definition,
        });
      }
      setTutors(res.body);
    }
  }, []);

  const tutorOptions =
    tutors.map(
      (d): IOption => ({
        label: d.definition,
        value: d.id.toString(),
      }),
    ) || [];

  useEffect(() => {
    loadTutors();
  }, [loadTutors]);

  const handleSubmit = async (event: SyntheticEvent) => {
    ReactGA.event({
      category: 'Button',
      action: 'Create course modal - Add course',
    });

    event.preventDefault();

    const res = await save();
    if (res.success) {
      loadLearner && loadLearner();
      closeModal();
      window.location.reload();
    }
  };

  const tutorError = validateField(learner?.tutorId?.toString(), {
    required: true,
  });
  const isValid = !tutorError;

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        <CreateCourseHeader>
          <IconPlus width={25} height={25} />
          <h2>Assign Tutor</h2>
        </CreateCourseHeader>
        <p>Which tutor would you like to assign to this learner?</p>
        {tutors && tutors?.length === 1 && (
          <ErrorMessage>
            <IconError height={20} width={20} />
            There are no tutors assigned to this course
          </ErrorMessage>
        )}
        <Select
          id="select-tutor"
          label="Tutor"
          onOptionChanged={(option: { value: string; label: string }) => {
            update(option.value, 'tutorId');
          }}
          placeholder="Select tutor..."
          options={tutorOptions}
          dataQa="learner-dropdown-tutor"
          initalValue={initialOption}
          value={learner?.tutor || ''}
          error={tutorError}
          attachOutsideOfModal
        />
        <ButtonRow>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Learner add tutorModal modal - Assign tutor',
              });
              closeModal();
            }}
            variant="subtle"
            dataQa="learnerAddTutorModal-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            iconLeft={<IconPlus width={15} height={15} />}
            type="submit"
            disabled={!isValid}
            dataQa="learnerAddTutorModal-btn-add"
          >
            Assign Tutor
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default LearnerAddTutor;
