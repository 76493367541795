import { useContext, useEffect, useState } from 'react';
import set from 'lodash/set';
import produce from 'immer';
import { fetchAPI } from '../../../utils/fetch-api';
import { LearnerContext } from '../../../context-providers/learner/learner-context';

export const useEditLearner = () => {
  const { state, dispatch } = useContext(LearnerContext);
  const current = state.currentLearner;
  const [temp, setTemp] = useState(current);
  useEffect(() => setTemp(current), [current]);

  const update = (value: any, field: string) => {
    if (temp) {
      setTemp(
        produce(temp, (draft) => {
          set(draft, field, value);
          return draft;
        }),
      );
    }
  };

  const save = async () => {
    const res = await fetchAPI({
      method: 'PATCH',
      body: temp,
      path: `Learners/${temp?.id}`,
      responseType: 'text',
    });
    if (res.success) {
      dispatch({ type: 'SET_LEARNER', payload: temp });
    }
    return res;
  };

  return { learner: temp, update, save };
};
