import React, { SyntheticEvent, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import {
  CreateCourseHeader,
  CreateCourseSubtitle,
} from '../ModalContent.style';
import { IconEdit, IconPlus } from '../../icons';
import {
  getAllQueryVar,
  urlWithSetMultipleQueryVar,
} from '../../../utils/query-vars';
import {
  ILearner,
  ILearnerAdmin,
} from '../../../context-providers/learner/learner-reducer';
import AdminsFilter from '../../atoms/filter/filter-types/admins-filter';
import { navigate } from 'gatsby';

interface IAssignAdmins {
  learner: ILearner;
  onComplete: () => void;
}

const EditLearnerAdmins = ({ learner, onComplete }: IAssignAdmins) => {
  const closeModal = useCloseModal();
  const [editedLearner] = useState<ILearner>(learner);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (isMenuOpen: boolean): void => {
    setIsMenuOpen(isMenuOpen);
  };

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const showCurrentAdmins = () => {
    let url = '/';

    if (editedLearner.adminIds !== null) {
      let idSplit: string[] = editedLearner.adminIds.split(',');
      url = urlWithSetMultipleQueryVar(
        'admins',
        idSplit.map((i) => i.toString()),
      );
      navigate(url);
    }
  };

  useEffect(() => {
    showCurrentAdmins();
  }, []);

  const adminsParams = extractQueryVars('admins');

  const handleSubmit = async (event: SyntheticEvent) => {
    ReactGA.event({
      category: 'Button',
      action: 'Add admin modal - Add admins',
    });

    const adminAssign: ILearnerAdmin = {
      id: editedLearner?.id,
      adminIdList: adminsParams.join(','),
    };

    event.preventDefault();

    const res = await fetchAPI({
      path: `learners/updateadmins/${editedLearner?.id}`,
      body: adminAssign,
      method: 'PATCH',
      responseType: 'text',
    });
    if (res.success) {
      onComplete && onComplete();
      closeModal();
      window.location.reload();
    }
  };
  const notChanged = adminsParams.join(',') === editedLearner.adminIds;

  const adminsErr = adminsParams.length < 0 || notChanged;
  const isValid = !adminsErr;

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
      style={isMenuOpen ? { maxWidth: 412, height: 550 } : { maxWidth: 412 }}
    >
      <Stack stackMultiplier={2}>
        <CreateCourseHeader>
          <IconPlus width={25} height={25} />
          <h2>Edit Assigned Admin(s)</h2>
        </CreateCourseHeader>
        <CreateCourseSubtitle>Assign Admins</CreateCourseSubtitle>
        <p>
          Admins have full control of learner management and can send and review
          forms.
        </p>

        <AdminsFilter toggleMenu={toggleMenu} />
        <ButtonRow>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Add admins modal - Add course',
              });
              closeModal();
            }}
            variant="subtle"
            dataQa="editAdminsModal-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            iconLeft={<IconEdit width={15} height={15} />}
            type="submit"
            disabled={!isValid}
            dataQa="editAdminsModal-btn-edit"
          >
            Edit admin(s)
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditLearnerAdmins;
