type TimerObservable = () => Promise<void>;
export class TimerSubject {
  private observer: TimerObservable | undefined;
  private interval: NodeJS.Timer | undefined;
  private time: number;

  public constructor(timeInSeconds: number) {
    this.time = timeInSeconds * 1000;
  }

  public subscribe(observer: TimerObservable) {
    this.observer = observer;
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.observer && this.observer();
      }, this.time);
    }
  }

  public unsubscribe() {
    this.observer = undefined;
  }

  public destroyTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  public resetTimer() {
    if (!this.interval) {
      return;
    }

    this.destroyTimer();
    this.interval = setInterval(() => {
      this.observer && this.observer();
    }, this.time);
  }
}
export default TimerSubject;
