import React from 'react';
import {
  StaticToolTipWrapper,
  StaticToolTipLearnerLLDDDetails,
} from './static-tooltip.style';

interface StaticToolTipProps {
  children: JSX.Element;
  text: string;
}

const TooltipLearnerLLDDDetails = ({ text, children }: StaticToolTipProps) => {
  return (
    <StaticToolTipWrapper>
      <StaticToolTipLearnerLLDDDetails className="StaticToolTip">
        {text}
      </StaticToolTipLearnerLLDDDetails>
      {children}
    </StaticToolTipWrapper>
  );
};

export default TooltipLearnerLLDDDetails;
