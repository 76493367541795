import styled from 'styled-components';
import { color } from '../../config';

export const EditableTitleContainer = styled.div`
  position: relative;
  max-width: 80rem;
`;

export const EditableTitleTitle = styled.div`
  display: flex;
  align-items: flex-start;
  h3 {
    flex: 1;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 2rem;
  }
  align-items: center;
`;

export const EditableTitleIcon = styled.button`
  color: ${color.primary.base};
  padding-left: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  svg {
    width: 1.5rem;
  }
`;
