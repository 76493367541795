import { useCallback, useContext, useEffect, useState } from 'react';
import { fetchAPI } from '../../utils/fetch-api';
import { getQueryVar } from '../../utils/query-vars';
import { LearnerContext } from './learner-context';
import { ILearner } from './learner-reducer';

/**
 * Load full learner
 */
export const useLearner = (preload = false) => {
  const learnerId = getQueryVar('lid');

  const learnerContext = useContext(LearnerContext);
  const [isLoading, setIsLoading] = useState(false);
  const learner = learnerContext.state.currentLearner;
  const { dispatch } = learnerContext;

  const loadLearner = useCallback(async () => {
    setIsLoading(true);
    const res = await fetchAPI<ILearner>({
      path: `learners/${learnerId}`,
    });
    if (res.success) {
      dispatch({ type: 'SET_LEARNER', payload: res.body });
    }
    setIsLoading(false);
  }, [dispatch, learnerId]);

  useEffect(() => {
    if (preload) {
      loadLearner();
    }
  }, [preload, loadLearner]);

  return { learner, loadLearner, isLoading };
};
