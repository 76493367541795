import React from 'react';
import AuthWrapper from '../../components/layout/AuthWrapper';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import LearnerDetails from '../../components/organisms/Learner/Learner';

const LeanerPage = () => {
  return (
    <AuthWrapper>
      <Head title="Learner" />
      <Layout
        section="learners"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/list-learners', label: 'Learners' },
          { label: 'Edit learner' },
        ]}
      >
        <LearnerDetails />
      </Layout>
    </AuthWrapper>
  );
};

export default LeanerPage;
