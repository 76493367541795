import React, { SyntheticEvent, useState } from 'react';
import download from 'downloadjs';
import { fetchAPI } from '../../../utils/fetch-api';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Radio from '../../atoms/form-fields/radio/Radio';
import Label from '../../atoms/form-fields/label/Label';
import Stack from '../../atoms/stack/Stack';
import { IconDownload } from '../../icons';
import { Wrapper } from '../ModalContent.style';
import { useCurrentLearner } from '../../../context-providers/learner/learner-context';

type TFileFormat = 'csv' | 'xml';

const ExportLearnerDetails = () => {
  const closeModal = useCloseModal();

  const learner = useCurrentLearner();
  const [format, setFormat] = useState<TFileFormat>('csv');

  // format and enforce download of filetype
  const downloadData = (data: Blob | string) => {
    let contentType = 'plain';
    let downloadContent = data;

    if (format === 'csv') {
      contentType = 'csv';
      downloadContent = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    }

    const fileName = `${learner?.firstName}-${learner?.surname}-details.${format}`;

    download(downloadContent, fileName, `text/${contentType}`);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const res = await fetchAPI<Blob | string>({
      method: 'GET',
      path: `Learners/${learner?.id}/export?format=${format === 'csv' ? 1 : 2}`,
      responseType: 'text',
    });
    if (res.success) {
      downloadData(res.body);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <h2>Export record</h2>
        <Stack stackMultiplier={0.5}>
          <Stack stackMultiplier={0.5}>
            <Label htmlFor="csv">Select format</Label>
            <Stack stackMultiplier={1}>
              <p>Which data format would you like to export the record?</p>
              <Wrapper>
                <Radio
                  key="csv"
                  id="csv"
                  label="CSV"
                  inline
                  checked={format === 'csv'}
                  onChange={() => setFormat('csv')}
                  dataQa="export-radio-csv"
                />
                <Radio
                  key="xml"
                  id="xml"
                  inline
                  label="XML"
                  checked={format === 'xml'}
                  onChange={() => setFormat('xml')}
                  dataQa="export-radio-xml"
                />
              </Wrapper>
            </Stack>
          </Stack>
        </Stack>
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="export-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            iconRight={<IconDownload responsive />}
            dataQa="export-btn-download"
          >
            Download
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default ExportLearnerDetails;
