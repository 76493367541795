import React from 'react';
import {
  StaticToolTipWrapper,
  StaticToolTipLearnerDetails,
} from './static-tooltip.style';

interface StaticToolTipProps {
  children: JSX.Element;
  text: string;
}

const TooltipLearnerDetails = ({ text, children }: StaticToolTipProps) => {
  return (
    <StaticToolTipWrapper>
      <StaticToolTipLearnerDetails className="StaticToolTip">
        {text}
      </StaticToolTipLearnerDetails>
      {children}
    </StaticToolTipWrapper>
  );
};

export default TooltipLearnerDetails;
