import React, { SyntheticEvent, useState } from 'react';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { useEditLearner } from './useEditLearner';

export interface IEditLearnerAddress {
  loadLearner?: () => void;
}

const EditLearnerAddress = ({ loadLearner }: IEditLearnerAddress) => {
  const closeModal = useCloseModal();
  const { learner, update, save } = useEditLearner();
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const res = await save();
    if (res.success) {
      loadLearner && loadLearner();
      closeModal();
    }
  };

  const handleFieldChange = (str: string, field: string) => {
    setIsDirty(true);
    update(str, field);
  };

  // field errors
  const { building, street, county, city, postcode } = learner?.address || {};
  const buildingErr = validateField(building, {
    building: true,
  });
  const streetErr = validateField(street, {
    street: true,
  });
  const countyErr = validateField(county, {
    county: true,
  });
  const cityErr = validateField(city, {
    city: true,
  });
  const postcodeErr = validateField(postcode, {
    postCode: true,
  });
  const isValid =
    !buildingErr && !streetErr && !countyErr && !cityErr && !postcodeErr;

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <h2>Edit learner address</h2>
        <Stack stackMultiplier={0.5}>
          <TextInput
            label="Address line 1 (building name or number)"
            value={building || ''}
            id="building"
            error={buildingErr}
            onChangeText={(str) => handleFieldChange(str, 'address.building')}
            dataQa="editAddress-input-building"
          />
          <TextInput
            label="Address line 2 (street name)"
            value={street || ''}
            id="street"
            error={streetErr}
            onChangeText={(str) => handleFieldChange(str, 'address.street')}
            dataQa="editAddress-input-street"
          />
        </Stack>
        <TextInput
          label="Town or city"
          value={city || ''}
          id="city"
          error={cityErr}
          onChangeText={(str) => handleFieldChange(str, 'address.city')}
          dataQa="editAddress-input-city"
        />
        <TextInput
          label="County"
          value={county || ''}
          id="county"
          error={countyErr}
          onChangeText={(str) => handleFieldChange(str, 'address.county')}
          dataQa="editAddress-input-county"
        />
        <TextInput
          label="Postcode"
          value={postcode || ''}
          id="postcode"
          error={postcodeErr}
          onChangeText={(str) => handleFieldChange(str, 'address.postcode')}
          dataQa="editAddress-input-postcode"
        />
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editAddress-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid || !isDirty || isSubmitting}
            dataQa="editAddress-btn-save"
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditLearnerAddress;
