import React, { SyntheticEvent } from 'react';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { useEditLearner } from './useEditLearner';

export interface IEditLearnerName {
  loadLearner?: () => void;
}

const EditLearnerName = ({ loadLearner }: IEditLearnerName) => {
  const closeModal = useCloseModal();
  const { learner, update, save } = useEditLearner();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const res = await save();
    if (res.success) {
      loadLearner && loadLearner();
      closeModal();
    }
  };

  // field errors
  const firstNameErr = validateField(learner?.firstName, {
    required: true,
    firstName: true,
  });
  const surnameErr = validateField(learner?.surname, {
    required: true,
    lastName: true,
  });
  const isValid = !firstNameErr && !surnameErr;

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <h2>Edit learner name</h2>
        <TextInput
          label="First name"
          value={learner?.firstName || ''}
          id="firstName"
          error={firstNameErr}
          onChangeText={(str) => update(str, 'firstName')}
          dataQa="editName-input-firstName"
        />
        <TextInput
          label="Surname"
          value={learner?.surname || ''}
          id="surname"
          error={surnameErr}
          onChangeText={(str) => update(str, 'surname')}
          dataQa="editName-input-surname"
        />
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editName-btn-cancel"
          >
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid} dataQa="editName-btn-save">
            Save details
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditLearnerName;
