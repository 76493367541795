import React, { SyntheticEvent, useState } from 'react';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { useEditLearner } from './useEditLearner';
import { MaxLength } from '../../../context-providers/enums/global-enums';
import { set } from 'lodash';

export interface IEditLearnerContact {
  loadLearner?: () => void;
}

const EditLearnerContact = ({ loadLearner }: IEditLearnerContact) => {
  const closeModal = useCloseModal();
  const { learner, update, save } = useEditLearner();
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const res = await save();
    if (res.success) {
      loadLearner && loadLearner();
      closeModal();
    }
  };

  const handleFieldChange = (str: string, field: string) => {
    setIsDirty(true);
    update(str, field);
  };

  // field errors
  const contact = learner?.contact;
  const homeErr = validateField(contact?.homePhoneNumber, {
    homeNumber: true,
  });
  const mobileErr = validateField(contact?.mobilePhoneNumber, {
    mobileNumber: true,
  });
  const emailErr = validateField(contact?.emailAddress, {
    email: true,
  });
  const isValid = !homeErr && !mobileErr && !emailErr;

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <h2>Edit learner contact</h2>
        <TextInput
          label="Home contact number"
          id="homePhoneNumber"
          value={contact?.homePhoneNumber || ''}
          error={homeErr}
          onChangeText={(str) =>
            handleFieldChange(str, 'contact.homePhoneNumber')
          }
          dataQa="editContact-input-home"
        />
        <TextInput
          label="Mobile number"
          id="mobilePhoneNumber"
          value={contact?.mobilePhoneNumber || ''}
          error={mobileErr}
          onChangeText={(str) =>
            handleFieldChange(str, 'contact.mobilePhoneNumber')
          }
          dataQa="editContact-input-mobile"
        />
        <TextInput
          label="Email address"
          id="emailAddress"
          maxLength={MaxLength.EmailAddress}
          value={contact?.emailAddress || ''}
          error={emailErr}
          onChangeText={(str) => handleFieldChange(str, 'contact.emailAddress')}
          dataQa="editContact-input-email"
        />
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editContact-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid || !isDirty || isSubmitting}
            dataQa="editContact-btn-save"
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditLearnerContact;
