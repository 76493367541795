import { SaveStatus } from '../enums/global-enums';
import { TimerSubject } from '../timer-subject/timer-subject';
import { IBuildFormAutoSaveData } from './BuildFormAutoSaveData';

type BuildFormAutoSaveObserver = (
  formSavedData: IBuildFormAutoSaveData,
) => void;

class BuildFormAutoSaveSubject {
  private observer: BuildFormAutoSaveObserver | undefined;
  private formSavedData: IBuildFormAutoSaveData = {
    savedTime: this.getDate(),
    savedStatus: SaveStatus.NotSaved,
  };
  private fiveMinuteTimer = new TimerSubject(5 * 60);

  public get timer(): TimerSubject {
    return this.fiveMinuteTimer;
  }

  public subscribe(observer: BuildFormAutoSaveObserver) {
    this.observer = observer;
  }

  public setFormSaving() {
    this.setFormSavedData(SaveStatus.Saving);
  }

  public setFormSavedSuccess() {
    this.setFormSavedData(SaveStatus.Saved);
  }

  public setFormSavedFailed() {
    this.setFormSavedData(SaveStatus.Failed);
  }

  private setFormSavedData(status: SaveStatus) {
    this.formSavedData = {
      savedStatus: status,
      savedTime: this.getDate(),
    };

    if (this.observer) {
      this.observer(this.formSavedData);
    }
  }

  private getDate(): string {
    const date = new Date();
    let hours = date.getHours();
    let minutes: number | string = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${minutes}${ampm}`;
  }
}

const buildFormAutoSaveSubject = new BuildFormAutoSaveSubject();

export default buildFormAutoSaveSubject;
