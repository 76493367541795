import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import {
  deleteQueryParams,
  getAllQueryVar,
  urlWithSetMultipleQueryVar,
} from '../../../../utils/query-vars';
import TableFilter from '../TableFilter';
import FilterService from '../service/filter-service';
import { FilterEntityResponse } from '../models/filter-response';
import { FilterItem } from '../models/filter-item';
import { TabColour } from '../../pill/tab-color';

interface FilterProps {
  toggleMenu: (isMenuOpen: boolean) => void;
}

const AdminsFilter = ({ toggleMenu }: FilterProps) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<FilterEntityResponse[]>([]);

  const load = async () => {
    const response = await FilterService.loadAdminNames();
    if (response.success) {
      setItems(response.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onItemChanged = (selectedItems: FilterItem[]): void => {
    let url = '/';
    if (selectedItems.length === 0) {
      url = deleteQueryParams('admins');
    } else {
      url = urlWithSetMultipleQueryVar(
        'admins',
        selectedItems.map((i) => i.id.toString()),
      );
    }

    navigate(url);
  };

  useEffect(() => {
    load();
  }, []);

  let adminQuery = getAllQueryVar('admins') ?? [];
  if (adminQuery.length === 1) {
    adminQuery = adminQuery[0].split(',');
  }

  const checkedItems: FilterItem[] = items.map((i) => ({
    id: i.id,
    name: i.name,
    color: TabColour.blue,
    selected: adminQuery.includes(i.id.toString()),
  }));

  return (
    <TableFilter
      filterName="admin-name"
      items={checkedItems}
      title="Admin"
      disabled={loading}
      searchTitle="Search for an admin"
      onItemChanged={onItemChanged}
      toggleMenu={toggleMenu}
    />
  );
};

export default AdminsFilter;
