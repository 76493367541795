import React, { useEffect, useState } from 'react';
import { SaveStatus } from '../../../context-providers/enums/global-enums';
import {
  useEditForm,
  useNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { IconCheck, IconRefresh } from '../../icons';
import { SaveFormTag } from './saveformpill.style';
import buildFormAutoSaveSubject from '../../../context-providers/build-form-autosave/BuildFormAutoSaveSubject';
import { IBuildFormAutoSaveData } from '../../../context-providers/build-form-autosave/BuildFormAutoSaveData';

const SaveFormPill = () => {
  const newForm = useNewForm();
  const saveForm = useEditForm();

  const [formSavedData, setFormSavedData] = useState<
    IBuildFormAutoSaveData | undefined
  >();

  buildFormAutoSaveSubject.timer.subscribe(async () => {
    if (newForm.formData.length > 0) {
      buildFormAutoSaveSubject.setFormSaving();
      const res = await saveForm('saved');

      if (res.success) {
        buildFormAutoSaveSubject.setFormSavedSuccess();
      } else {
        buildFormAutoSaveSubject.setFormSavedFailed();
      }
    }
  });

  buildFormAutoSaveSubject.subscribe((newFormSavedData) => {
    setFormSavedData(newFormSavedData);
  });

  useEffect(() => {
    return () => {
      buildFormAutoSaveSubject.timer.unsubscribe();
    };
  }, []);

  if (
    !formSavedData ||
    formSavedData.savedStatus === SaveStatus.NotSaved ||
    formSavedData.savedStatus === SaveStatus.Failed
  ) {
    return null;
  } else if (formSavedData.savedStatus === SaveStatus.Saving) {
    return (
      <SaveFormTag>
        <>
          <IconRefresh />
          <>Form saving</>
        </>
      </SaveFormTag>
    );
  } else {
    return (
      <SaveFormTag>
        <>
          <IconCheck />
          Saved at {formSavedData.savedTime}
        </>
      </SaveFormTag>
    );
  }
};

export default SaveFormPill;
