import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { validateField } from '../../../context-providers/forms/forms-validation';
import {
  ILearnerLLDD,
  ILearnerSex,
} from '../../../context-providers/learner/learner-reducer';
import { ILearnerEthnicity } from '../../../context-providers/learner/learner-reducer';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import DateInput from '../../atoms/form-fields/date-input/DateInput';
import Select, { IOption } from '../../atoms/form-fields/select/Select';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { useEditLearner } from './useEditLearner';

export interface IEditLearnerDetails {
  loadLearner?: () => void;
}

const EditLearnerDetails = ({ loadLearner }: IEditLearnerDetails) => {
  const closeModal = useCloseModal();
  const { learner, update, save } = useEditLearner();
  const [lldds, setLldds] = useState<ILearnerLLDD[]>([]);
  const [ethnicities, setEthnicities] = useState<ILearnerEthnicity[]>([]);
  const [sex, setSex] = useState<ILearnerSex[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadLLDDs = useCallback(async () => {
    const res = await fetchAPI<ILearnerLLDD[]>({
      path: `learners/lldd`,
      method: 'GET',
    });

    if (res.success) {
      setLldds(res.body);
    }
  }, []);

  const llddOptions =
    lldds.map(
      (d): IOption => ({
        label: d.definition,
        value: String(d.code),
      }),
    ) || [];

  const loadEthnicities = useCallback(async () => {
    const res = await fetchAPI<ILearnerEthnicity[]>({
      path: `learners/ethnicities`,
      method: 'GET',
    });

    if (res.success) {
      setEthnicities(res.body);
    }
  }, []);

  const ethnicityOptions =
    ethnicities.map(
      (d): IOption => ({
        label: d.definition,
        value: String(d.code),
      }),
    ) || [];

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    const res = await save();
    if (res.success) {
      loadLearner && loadLearner();
      closeModal();
    }
  };

  const loadSex = useCallback(async () => {
    const res = await fetchAPI<ILearnerSex[]>({
      path: `learners/sex`,
      method: 'GET',
    });

    if (res.success) {
      setSex(res.body);
    }
  }, []);

  const sexOptions =
    sex.map(
      (d): IOption => ({
        label: d.definition,
        value: d.code.toString(),
      }),
    ) || [];

  useEffect(() => {
    loadEthnicities();
    loadLLDDs();
    loadSex();
  }, []);

  // field errors
  const niError = validateField(learner?.nationalInsuranceNumber, {
    nationalInsurance: true,
  });
  const ulnError = validateField(learner?.uln, {
    maxLength: 10,
    minLength: 10,
    ulnNumber: true,
  });

  const isValidEthnicityCode = (ethnicityCode: number | undefined) => {
    if (!ethnicityCode) {
      return true;
    }

    if (ethnicityCode === 0 || ethnicityCode > 99) {
      return false;
    }

    return true;
  };

  const ethnicityCodeError = isValidEthnicityCode(learner?.ethnicityCode)
    ? ''
    : 'EthnicityCode is required';

  const isValid = !niError && !ulnError && !ethnicityCodeError;

  const handleFieldChange = (value: string, field: string) => {
    update(value, field);
    setIsDirty(true);
  };

  const initialSexField: IOption | undefined = sex
    ? {
        label: `${learner?.sex}`,
        value: `${learner?.sexCode}`,
      }
    : undefined;

  const initialLlddField: IOption | undefined = lldds
    ? {
        label: `${learner?.lldd}`,
        value: `${learner?.llddCode}`,
      }
    : undefined;

  const initialEthnicityField: IOption | undefined = ethnicities
    ? {
        label: `${learner?.ethnicity}`,
        value: `${learner?.ethnicityCode}`,
      }
    : undefined;

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <h2>Edit learner details</h2>
        <DateInput
          label="Date of Birth"
          validateLearner
          value={learner?.dateOfBirth || ''}
          onChange={(str) => handleFieldChange(str, 'dateOfBirth')}
          required={false}
        />
        <TextInput
          type="number"
          label="ULN"
          description="Valid ULN required (max 10 digits)"
          id="uln"
          error={ulnError}
          value={learner?.uln || ''}
          onChangeText={(str) => handleFieldChange(str, 'uln')}
          dataQa="editDetails-input-uln"
        />
        <TextInput
          label="NI Number"
          description="Valid NI Number required (e.g AA123456C)"
          id="nationalInsuranceNumber"
          error={niError}
          value={learner?.nationalInsuranceNumber || ''}
          dataQa="editDetails-input-ni"
          onChangeText={(str) =>
            handleFieldChange(str.toUpperCase(), 'nationalInsuranceNumber')
          }
        />
        <Select
          id="select-ethnicity"
          label="Ethnicity"
          placeholder="Select an ethnicity..."
          initalValue={initialEthnicityField}
          error={ethnicityCodeError}
          options={ethnicityOptions}
          dataQa="learner-dropdown-ethnicity"
          required={false}
          onOptionChanged={(option) =>
            handleFieldChange(option.value, 'ethnicityCode')
          }
        />
        <Select
          id="select-sex"
          label="Sex"
          placeholder="Select a Sex"
          initalValue={initialSexField}
          value={learner?.sex || ''}
          options={sexOptions}
          dataQa="learner-dropdown-sex"
          required={false}
          onOptionChanged={(option) =>
            handleFieldChange(option.value, 'sexCode')
          }
        />
        <Select
          id="select-lldd"
          label="LLDD"
          placeholder="Select an LLDD..."
          initalValue={initialLlddField}
          options={llddOptions}
          dataQa="learner-dropdown-lldd"
          required={false}
          onOptionChanged={(option: { value: string }) =>
            handleFieldChange(option.value, 'llddCode')
          }
        />
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editDetails-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid || !isDirty || isSubmitting}
            dataQa="editDetails-btn-save"
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditLearnerDetails;
