import React from 'react';
import { IconEdit } from '../../icons';
import SaveFormPill from '../saveformpill/saveformpill';
import {
  EditableTitleContainer,
  EditableTitleIcon,
  EditableTitleTitle,
} from './EditableTitle.style';

export interface EditableTitleProps {
  onEdit?: () => void;
  isEmpty?: boolean;
  formNameCheck?: boolean;
  title: string;
  showSavingPill?: boolean;
}

const EditableTitle = ({
  title,
  onEdit = () => {},
  isEmpty = false,
  showSavingPill = false,
}: EditableTitleProps) => {
  return (
    <EditableTitleContainer>
      <EditableTitleTitle>
        <h2 data-qa="form-lbl-name">{title}</h2>

        {!isEmpty && (
          <EditableTitleIcon
            data-qa="form-btn-edit-name"
            onClick={() => {
              onEdit();
            }}
          >
            <IconEdit />
            <span className="sr-only">Edit title</span>
          </EditableTitleIcon>
        )}
        {showSavingPill && <SaveFormPill />}
      </EditableTitleTitle>
    </EditableTitleContainer>
  );
};

export default EditableTitle;
