import styled from 'styled-components';
import { color } from '../../config';

export const SaveFormTag = styled.div`
  align-items: center;
  background-color: ${color.grey.base};
  border-radius: 0.5em;
  color: ${color.light};
  cursor: default;
  display: flex;
  flex-shrink: 0;
  font-size: 1em;
  line-height: 1.5em;
  margin-left: auto;
  padding: 0.4rem 0.8rem;

  svg {
    color: ${color.light};
    margin-right: 0.4rem;
  }
`;
