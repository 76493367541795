import React, { SyntheticEvent, useState } from 'react';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { ILearnerCentre } from '../../../utils/exportLearnerHelper';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';
import { FilterItem } from '../../atoms/filter/models/filter-item';
import Stack from '../../atoms/stack/Stack';
import { useEditLearner } from './useEditLearner';

export interface IAssignCentreToLearner {
  loadLearner?: () => void;
  centres: ILearnerCentre[];
}

const AssignCentreToLearner = ({
  loadLearner,
  centres,
}: IAssignCentreToLearner) => {
  const closeModal = useCloseModal();
  const { learner, update, save } = useEditLearner();

  const [selectedCentre, setSelectedCentre] = useState<ILearnerCentre>();

  const centreOptions: FilterItem[] = centres.map((i) => ({
    id: i.id,
    name: i.name,
  }));

  const onCentreChanged = (selectedItem: FilterItem): void => {
    const value = centres?.find((l) => l.id === selectedItem.id);
    update(value, 'centre');
    setSelectedCentre(value);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (learner?.centre) {
      const res = await save();
      if (res.success) {
        loadLearner && loadLearner();
        closeModal();
      }
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (isMenuOpen: boolean): void => {
    setIsMenuOpen(isMenuOpen);
  };

  const initialValue: FilterItem | undefined = learner?.centre
    ? learner.centre
    : undefined;
  const modalTitle: string = learner?.centre
    ? 'Edit Assigned Centre'
    : 'Assign Centre';

  return (
    <form
      onSubmit={handleSubmit}
      style={isMenuOpen ? { maxWidth: 412, height: 550 } : { maxWidth: 412 }}
    >
      <Stack>
        <h2>{modalTitle}</h2>
        <>
          <Stack>
            <h3>Assign E-Portfolio Centre</h3>
            <p>
              The E-Portfolio centre the enrolled learner would belong to is
              required for any relevent event subscriptions.
            </p>
            <FilterSingleItem
              filterName="centre-filter"
              items={centreOptions}
              title="Filter by centre"
              searchTitle="Search Centre"
              initalValue={initialValue}
              onItemChanged={onCentreChanged}
              toggleMenu={toggleMenu}
            />
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => closeModal()}
              variant="subtle"
              dataQa="editCente-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!selectedCentre}
              dataQa="editEmployer-btn-save"
            >
              Save details
            </Button>
          </ButtonRow>
        </>
      </Stack>
    </form>
  );
};

export default AssignCentreToLearner;
